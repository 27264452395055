<template>
    <div class="form_grp" v-if="isContact">
        <div class="group_item">
            <label class="input_label">Contact Number <a class="option" @click="phoneAdd = true">Add Phone</a></label>
            <div class="field_wpr">
                <input type="text" v-model="phone" >
            </div>
        </div>
    </div>
    <div v-if="phoneAdd" class="edit_section">
        <div class="section_title">Add to Address Book</div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Name</label>
                <div class="field_wpr has_prefix">
                    <input type="text" placeholder="John Doe" >
                    <span class="prefix"><i class="fas fa-user"></i></span>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Phone</label>
                <div class="field_wpr">
                    <input type="text" v-model="phone" >
                </div>
            </div>
        </div>
        <div class="action_wpr">
            <button class="btn cancel_btn" @click="phoneAdd = false">Cancel</button>
            <button class="btn save_btn">Add</button>
        </div>
    </div>
    <div class="form_grp">
        <div class="group_item">
            <div class="input_label">
                Send SMS
                <emoji-picker :onSelectEmoji="setSmsEmoji" classes="subject-emoji-picker ml-auto mr-2" />
                <div class="dropdown mr-2" @click="smsOption = !smsOption">
                    <i class="fas fa-user-alt"></i>
                    <div class="drp_wrapper" v-if="smsOption" :class="{active : smsOption}">
                        <ul>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Full Name</li>
                            <li>Phone</li>
                            <li>E-mail</li>
                            <li>Address</li>
                            <li>State</li>
                            <li>City</li>
                            <li>Country</li>
                            <li>Zip Code</li>
                            <li>DOB</li>
                            <li>Date Stamp</li>
                            <li>Time Stamp</li>
                            <li>Company Name</li>
                            <li>Owner First Name</li>
                            <li>Company Owner</li>
                            <li>Company Address</li>
                            <li>Company Website</li>
                            <li>Company Phone</li>
                            <li>Member ID</li>
                            <li>Form Name</li>
                            <li>Form URL</li>
                            <li>Form URL SMS</li>
                        </ul>
                    </div>
                </div>
                <div class="dropdown" @click="msgOption = !msgOption">
                    <i class="fas fa-ellipsis-v"></i>
                    <div class="drp_wrapper" v-if="msgOption" :class="{active : msgOption}">
                        <ul>
                            <li>Reset to default message</li>
                            <li @click="smsLibrary = !smsLibrary" :class="{active : smsLibrary}">Import template</li>
                            <li @click="testSMS = !testSMS" :class="{active : testSMS}">Send test SMS</li>
                            <li>Preview in browser</li>
                            <li>Save as template</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="field_wpr">
                <textarea placeholder="Type Message..." v-model="smsText"></textarea>
            </div>
            <span class="letter_count">0 / 160</span>
            <div v-if="smsLibrary" class="edit_section mt-4">
                <div class="section_title mb-1">Import template</div>
                <div class="library_wpr">
                    <div class="card_item"><img src="@/assets/images/sms.svg">SMS</div>
                    <div class="library_field">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Category</label>
                                <div class="field_wpr">
                                    <select v-model="templateType">
                                        <option disabled selected hidden>Select Category</option>
                                        <option value="Affiliate">Affilliate</option>
                                        <option value="Ascension">Ascension</option>
                                        <option value="Awareness">Awareness</option>
                                        <option value="Daily Email">Daily Email</option>
                                        <option value="Direct Pitch">Direct Pitch</option>
                                        <option value="Engagement">Engagement</option>
                                        <option value="Holiday">Holiday</option>
                                        <option value="Indirect Pitch">Indirect Pitch</option>
                                        <option value="Indoctrination">Indoctrination</option>
                                        <option value="One-Off">One-Off</option>
                                        <option value="Re-engagement">Re-engagement</option>
                                        <option value="Segmentation">Segmentation</option>
                                        <option value="Showcase">Showcase</option>
                                        <option value="Special Offer">Special Offer</option>
                                        <option value="Useful But Incomplete">Useful But Incomplete</option>
                                        <option value="Unassigned">Unassigned</option>
                                    </select>
                                    <span class="icon"><i class="fas fa-angle-down"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="templateType" class="form_grp">
                    <div class="group_item">
                        <div class="field_wpr">
                            <input type="text" placeholder="Search">
                        </div>
                    </div>
                </div>
                <div class="action_wpr mt-4">
                    <button class="btn cancel_btn" @click="smsLibrary = false">Cancel</button>
                    <button class="btn save_btn">Import</button>
                </div>
            </div>
            <div v-if="testSMS">
                <h3>Send Test SMS</h3>
                <div class="edit_section">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Phone Number</label>
                            <div class="field_wpr">
                                <input type="text" v-model="phone" >
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr mt-3">
                        <button class="btn cancel_btn" @click="testSMS = false">Cancel</button>
                        <button class="btn save_btn">Send Preview</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <label for="sms_attachment" class="switch_option capsule_btn">
        <h5>SMS Attachment<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
        <input type="checkbox" v-model="smsAttachment" id="sms_attachment" hidden>
        <div><span></span></div>
    </label>
    <div v-if="smsAttachment" class="upload_image">
        <label for="show_email">                   
            <input type="file" @change="fileSelected" id="show_email" hidden>
            <img src="../assets/images/image2.png">
            <h4>Click to Upload</h4>
            <p>or drag and drop SVG, PNG, JPG or GIF</p>
        </label>
    </div>
    <label for="smschedule" class="switch_option capsule_btn">
        <h5>Schedule SMS<span>Phasellus nec eros nec leo pharetra volutpat. Cras finibus enim sit amet elit ornare blandit.</span></h5>
        <input type="checkbox" v-model="smsSchedule" id="smschedule" hidden>
        <div><span></span></div>
    </label>
    <div v-if="smsSchedule" class="schedule_sms">
        <h3 class="sub_header">Schedule SMS</h3>
        <div class="edit_section">
            <Scheduletabs/>
            <div class="action_wpr mt-2">
                <button class="btn save_btn">Schedule</button>
            </div>
        </div>
    </div>
</template>

<script>
import Scheduletabs from './Scheduletabs.vue';
import EmojiPicker from '@/components/EmojiPicker'

export default {
    name: "SmsComp",
    props:{
        isContact: Boolean,
    },
    components:{
        Scheduletabs,
        EmojiPicker,
    },
    data(){
        return{
            phoneAdd: false,
            phone: false,
            smsOption: false,
            msgOption: false,
            smsLibrary: false,
            testSMS: false,
            templateType: '',
            smsAttachment: '',
            smsSchedule: false,
            smsText: '',
        }
    },
    methods:{
        setSmsEmoji(emoji){
            this.smsText = emoji;
        },
    }
}
</script>

<style scoped>
    :deep(.v3-input-emoji-picker) {
        margin: 0 0 0 auto;
    }

    :deep(.subject-emoji-picker .custom-emoji-picker) {
        top: 0;
    }

    :deep(.v3-input-picker-icon) {
        height: 0;
        margin-right: 5px;
    }
</style>