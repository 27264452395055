<template>
    <div @change="customChange">
        <label for="mail" class="switch_option capsule_btn">
            <h5>E-mail<span>Send E-mail Notification.</span></h5>
            <input type="radio" id="mail" value="mail" v-model="notification" hidden>
            <div><span></span></div>
        </label>
        <label for="sms" class="switch_option capsule_btn">
            <h5>SMS<span>Send SMS Notification.</span></h5>
            <input type="radio" id="sms" value="sms" v-model="notification" hidden>
            <div><span></span></div>
        </label>
        <label for="both" class="switch_option capsule_btn">
            <h5>Both<span>Send E-mail &amp; SMS Notification</span></h5>
            <input type="radio" id="both" value="both" v-model="notification" hidden>
            <div><span></span></div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'NotifyMethods',
    data(){
        return{
            notification: 'mail',
        }
    },
    methods: {
        customChange () {
            this.$emit("getData", this.notification);
        }
    },
}
</script>